(function($) {

  $.fn.toggleAria = function(e) {
    var i = "aria-" + e;
    return $(this)[0].hasAttribute(i) || console.log("Element has no " + i + " in the first place, adding it, setting it to true."), this.attr(i, function(t, e) {
      return "true" === e ? "false" : "true";
    }), this;
  };

  //WCAG cookie functions
  var createCookie = function(name, value, days) {
    var expires;
    if (days) {
      var date = new Date();
      date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
      expires = "; expires=" + date.toGMTString();
    } else {
      expires = "";
    }
    document.cookie = name + "=" + value + expires + "; path=/";
  };

  var readCookie = function(name) {
    var nameEQ = name + "=";
    var ca = document.cookie.split(';');
    for (var i = 0; i < ca.length; i++) {
      var c = ca[i];
      while (c.charAt(0) === ' ') {
        c = c.substring(1, c.length);
      }
      if (c.indexOf(nameEQ) === 0) {
        return c.substring(nameEQ.length, c.length);
      }
    }
    return null;
  };

  var eraseCookie = function(name) {
    createCookie(name, "", -1);
  };

  document.getElementById("btn-contrast").onclick = function(event) {
    event.preventDefault();
    y = readCookie('contrast');
    if (y === 'true') {
      createCookie('contrast', 'false', 30);
    } else {
      createCookie('contrast', 'true', 30);
    }
    location.reload();
  };

  var skipNav = function() {

    var focusable = function(element, isTabIndexNotNaN) {
      var map, mapName, img, nodeName = element.nodeName.toLowerCase();
      if ('area' === nodeName) {
        map = element.parentNode;
        mapName = map.name;
        if (!element.href || !mapName || map.nodeName.toLowerCase() !== 'map') {
          return false;
        }
        img = $('img[usemap=#' + mapName + ']')[0];
        return !!img && visible(img);
      }
      return (/input|select|textarea|button|object/.test(nodeName) ?
          !element.disabled :
          'a' === nodeName ?
          element.href || isTabIndexNotNaN :
          isTabIndexNotNaN) &&
        // the element and all of its ancestors must be visible
        visible(element);
    };
    //add :focusable selector
    $.extend($.expr[':'], {
      focusable: function(element) {
        return focusable(element, !isNaN($.attr(element, 'tabindex')));
      }
    });

    $('.btn-skip-nav').click(function(e) {
      e.preventDefault();
      var menu = $(this).next('nav'),
        focusables = $(':focusable'),
        index = focusables.index(menu.find(':focusable').last());
      focusables.eq(index + 1).focus();
    });
  };


  // Image menu function
  var imageMenu = function() {
    //If window width is higher than 767px and device doesn't support touch, enable desktop vesrion
    if (!Modernizr.touchevents && window.innerWidth > 767) {
      var visible = function(element) {
        return $.expr.filters.visible(element) && !$(element).parents().addBack().filter(function() {
          return $.css(this, 'visibility') === 'hidden';
        }).length;
      };

      var focusable = function(element, isTabIndexNotNaN) {
        var map, mapName, img, nodeName = element.nodeName.toLowerCase();
        if ('area' === nodeName) {
          map = element.parentNode;
          mapName = map.name;
          if (!element.href || !mapName || map.nodeName.toLowerCase() !== 'map') {
            return false;
          }
          img = $('img[usemap=#' + mapName + ']')[0];
          return !!img && visible(img);
        }
        return (/input|select|textarea|button|object/.test(nodeName) ?
            !element.disabled :
            'a' === nodeName ?
            element.href || isTabIndexNotNaN :
            isTabIndexNotNaN) &&
          // the element and all of its ancestors must be visible
          visible(element);
      };
      //add :focusable selector
      $.extend($.expr[':'], {
        focusable: function(element) {
          return focusable(element, !isNaN($.attr(element, 'tabindex')));
        }
      });

      var shifted;

      var showMenu = function(e) {
        var $this = $(this);
        if ($this.is(':focus') && shifted === true && !$this.hasClass('menu-active')) {
          e.preventDefault();
          $this.addClass('menu-active')
            .find('ul').attr({
              'aria-hidden': 'false',
              'aria-expanded': 'true'
            });
          $this.find('h3 a').attr('aria-expanded', 'true');
          $this.find(':focusable').last().focus();
        } else {
          $this.addClass('menu-active')
            .find('ul').attr({
              'aria-hidden': 'false',
              'aria-expanded': 'true'
            });
          $this.find('h3 a').attr('aria-expanded', 'true');
        }
      };

      var hideMenu = function() {
        $(this).removeClass('menu-active')
          .find('ul').attr({
            'aria-hidden': 'true',
            'aria-expanded': 'false'
          });
        $(this).find('h3 a').attr('aria-expanded', 'false');
      };

      var hideMenuFocus = function() {
        var self = $(this);
        if ($(this).find(':focusable').length === 0) {
          $(this).removeClass('menu-active');
        }
        setTimeout(function() {
          if (self.has(document.activeElement).length === 0 && !self.is(':focus')) {
            self.removeClass('menu-active')
              .find('ul').attr({
                'aria-hidden': 'true',
                'aria-expanded': 'false'
              });
            self.find('h3 a').attr('aria-expanded', 'false');
          }
        }, 1);
      };

      var menuHover = function() {
        $('.menu-image-hover .menu-item').hover(showMenu, hideMenu);
      };

      var menuFocus = function() {
        $('.menu-image-hover .menu-item').focusin(showMenu).focusout(hideMenuFocus);
      };

      var menuESC = function() {
        $('.menu-image-hover .menu-item').keydown(function(e) {
          if (e.keyCode === 27) {
            $(this).focus().removeClass('menu-active').attr('aria-expanded', 'false')
              .find('ul').attr({
                'aria-hidden': 'true',
                'aria-expanded': 'false'
              });
          }
        });
      };

      var skipMenu = function() {
        var menu = $(this).next('div'),
          focusables = $(':focusable'),
          index = focusables.index(menu.find(':focusable').last());

        focusables.eq(index + 1).focus();
      };

      $('.menu-image-hover .menu-item ul').attr('aria-hidden', 'true');

      $('#skip-menu').click(skipMenu);
      $(document).on('keyup keydown', function(e) {
        shifted = e.shiftKey;
      });

      menuFocus();
      menuHover();
      menuESC();
    } else {
      $('.menu-image-hover .menu-item').attr('aria-expanded', 'true').off();
      $('.menu-image-hover .menu-item ul').attr('aria-hidden', 'false');
    }
  };


  //Image menu with collapse
  var imageMenuCollapse = function() {
    if (!Modernizr.touchevents && window.innerWidth > 767) {
      if ($('.menu-image-hover-collapse').hasClass('transformed')) {
        $('.menu-image-hover-collapse').removeClass('transformed');
        $('.menu-image-hover-collapse .menu-item').removeClass('card');
        $('.menu-image-hover-collapse h3').each(function(index) {
          var $this = $(this);
          var titleText = $this.find('a').text();
          var titleUrl = $this.find('a').attr('href');
          $this.removeAttr('id').html('<a href="' + titleUrl + '">' + titleText + '</a>');
        });
        $('.menu-image-hover-collapse .collapse').each(function(index) {
          var $this = $(this);
          var menuHTML = $this.find('.card-body').html();
          $this.parent().append(menuHTML);
          $this.remove();
        });
      }
    } else {
      if (!$('.menu-image-hover-collapse').hasClass('transformed')) {
        $('.menu-image-hover-collapse').addClass('transformed');
        $('.menu-image-hover-collapse .menu-item').addClass('card');
        $('.menu-image-hover-collapse h3').each(function(index) {
          var $this = $(this);
          var titleText = $this.find('a').text();
          var titleUrl = $this.find('a').attr('href');
          $this.attr('id', 'image-collapse-title-' + index).html('<div class="collapse-overlay collapsed" data-toggle="collapse" data-target="#image-collapse-' + index + '" aria-expanded="false" aria-controls="image-collapse-' + index + '"><a href="' + titleUrl + '">' + titleText + '<span class="sub-arrow "></span></a></div>');
        });
        $('.menu-image-hover-collapse .collapse-menu-mobile').each(function(index) {
          var $this = $(this);
          $this.wrap('<div id="image-collapse-' + index + '" class="collapse" aria-labelledby="image-collapse-title-' + index + '" data-parent="#accordion"><div class="card-body"></div></div>');
        });
        $('.menu-image-hover-collapse .collapse-overlay a').click(function(e) {
          if ($(this).parent().attr('aria-expanded') === 'false' || e.target !== this) {
            e.preventDefault();
          }
        });
      }
    }
  };

  //remove scrollbar from body
  var originalBodyPad;
  var measureScrollbar = function() {
    var scrollDiv = document.createElement('div');
    scrollDiv.className = 'menu-scrollbar-measure';
    $('body').append(scrollDiv);
    var scrollbarWidth = scrollDiv.offsetWidth - scrollDiv.clientWidth;
    scrollDiv.remove();
    return scrollbarWidth;
  };

  var setScrollbar = function() {
    var scrollbarWidth = measureScrollbar();
    var bodyPad = parseInt(($('body').css('padding-right') || 0), 10);
    originalBodyPad = document.body.style.paddingRight || '';
    $('body').css('padding-right', bodyPad + scrollbarWidth).addClass('no-scrolling');
  };

  var resetScrollbar = function() {
    $('body').css('padding-right', originalBodyPad).removeClass('no-scrolling');
  };


  //initialize bxSliders
  var initializeBxSlider = function() {
    $('#carousel-row-logos').bxSlider({
      auto: true,
      autoControls: true,
      minSlides: 2,
      maxSlides: 8,
      moveSlides: 1,
      slideWidth: 280,
      nextText: '<i class="fas fa-chevron-right"></i>',
      prevText: '<i class="fas fa-chevron-left"></i>',
      startText: '<i class="fas fa-play"></i>',
      stopText: '<i class="fas fa-pause"></i>',
      name: 'logo',
      pager: false,
    });
  };

  // Remove single words from the last line
  var removeSingleWords = function() {
    $('#content p').each(function() {
      $(this).html($(this).html().replace(/\s((?=(([^\s<>]|<[^>]*>)+))\2)\s*$/, '&nbsp;$1'));
    });
  };

  // opening hours current day selector
  var openingHours = function() {
    var days = ['', 'mon', 'tue', 'wed', 'thu', 'fri', 'sat', 'sun'];
    var now = new Date();
    var currentDay = days[now.getDay()];
    $("." + currentDay).addClass('current-day');
  };

  //initialize Colorbox
  var initializeColorbox = function() {
    $('.gallery-colorbox').each(function() {
      $this = $(this);
      $this.find('a').colorbox({
        rel: $this,
        maxWidth: "100%",
        maxHeight: "100%",
        transition: "elastic",
        previous: '<i class="fas fa-chevron-left" aria-label="Previous photo"></i>',
        next: '<i class="fas fa-chevron-right" aria-label="Next photo"></i>',
        close: '<i class="fas fa-times" aria-label="Close gallery"></i>'
      });
    });

    $(document).bind('cbox_open', setScrollbar);

    $(document).bind('cbox_closed', resetScrollbar);
  };


  //something with tables
  var chnageTables = function() {
    $('.table-javascript').each(function() {
      var tableHeads = [];

      $(this).find('thead th').each(function() {
        tableHeads.push($(this).text());
      });

      $(this).find('tbody tr').each(function() {
        var colPos = 1;

        $(this).find('td').each(function() {
          $(this).prepend('<span class="d-none d-sm-block d-md-none">' + tableHeads[colPos] + ': </span>');

          colPos++;
        });
      });
    });
  };

  var showSearch = function() {
    $("#btn-search").click(function() {
      $(this).toggleAria("expanded");
      $("#search-box").slideToggle();
    });
  };

  var searchFormValidate = function() {
    $('.searchform').submit(function(e) {
      if ($(this).find('.form-control').val() === '') {
        e.preventDefault();
        if (!$(this).hasClass('validated')) {
          $(this).addClass('validated')
            .append('<div class="wpcf7-response-output wpcf7-display-none wpcf7-validation-errors" role="alert" style="display: block;">Search box can not be empty.</div>');
        }
      }
    });
  };

  var tabsKeyboardNav = function() {
    $('[role="tab"]').keydown(function(e) {
      var $this = $(this),
        allTabs = $this.closest('[role="tablist"]').find('[role="tab"]'),
        allTabsEq = allTabs.length, // get all tabs eq
        currentIndex = allTabs.index($this), // get current eq
        nextTab = allTabs.eq(currentIndex + 1), // get next tab DOM element
        prevTab = allTabs.eq(currentIndex - 1), // get previous tab DOM element
        target;

      if (e.keyCode == 39) {
        if (currentIndex + 1 == allTabsEq) return; //return if there is no next tab

        target = nextTab;
      }

      if (e.keyCode == 37) {
        if (currentIndex == 0) return; //return if there is no previous tab

        target = prevTab;
      }

      if (target) target.tab('show').focus();
    });
  };

  var collaspeKeyboardNav = function() {

    var delay = false,
      buttons = [35, 36, 38, 40];

    $('[data-toggle="collapse"]').keydown(function(e) {

      if ($.inArray(e.keyCode, buttons) >= 0) {
        e.preventDefault();
      }

      if (delay === true) {
        return;
      }

      var $this = $(this),
        allTabs = $this.closest('.flc-faq').find('[data-toggle="collapse"]'),
        allTabsEq = allTabs.length, // get all tabs eq
        currentIndex = allTabs.index($this), // get current eq
        nextTab = allTabs.eq(currentIndex + 1), // get next tab DOM element
        prevTab = allTabs.eq(currentIndex - 1),
        target; // get previous tab DOM element


      if (e.keyCode == 40) {
        target = currentIndex + 1 == allTabsEq ? allTabs.eq(0) : nextTab;
      }
      if (e.keyCode == 38) {
        target = currentIndex == 0 ? allTabs.eq(allTabsEq - 1) : prevTab;
      }
      if (e.keyCode == 36 && currentIndex !== 0) {
        target = allTabs.eq(0);
      }
      if (e.keyCode == 35 && currentIndex !== allTabsEq - 1) {
        target = allTabs.eq(allTabsEq - 1);
      }

      if (target) {
        target.click().focus();
      }

      delay = true;

      setTimeout(function() {
        delay = false;
      }, 500);

    })
  };

  var wcagTooltip = function() {

    var isEsc = false;

    $('[data-toggle="tooltip"]').tooltip({
      delay: {
        "show": 0,
        "hide": 500
      }
    });

    $('[data-toggle="tooltip"]').on('mouseenter mouseleave', function() {
      $(this).toggleClass('hovered');
    });

    $('[data-toggle="tooltip"]').on('show.bs.tooltip', function() {
      $('[data-toggle="tooltip"]').tooltip('hide');
    });

    $('[data-toggle="tooltip"]').on('hide.bs.tooltip', function() {
      var tooltip = $('.tooltip');
      var currentTooltip = $(this);

      if ($('.tooltip:hover').length > 0 && isEsc === false) {
        tooltip.on('mouseleave', function(e) {
          setTimeout(function() {
            if (tooltip.length > 0 && $('.tooltip:hover').length <= 0 && !currentTooltip.hasClass('hovered')) {
              currentTooltip.tooltip('hide');
            }
          }, 501);
        });
        return false;
      }
    });

    $(document).on('keydown', function(e) {
      if (e.keyCode === 27) {
        isEsc = true;
        $('[data-toggle="tooltip"]').tooltip('hide');
      }
    });

    $(document).on('keyup', function(e) {
      if (e.keyCode === 27) {
        isEsc = false;
      }
    });
  };

  var navTop = {
    position: null
  };
  var navBottom = {
    position: null
  };

  var fixedNav = function(nav, placement, navObject, marginObject) {

    if (placement === 'top') {
      if (navObject.position === null) {
        navObject.position = nav.position().top;
      }
      if ($(window).scrollTop() >= navObject.position) {
        nav.addClass('fixed-top');
        if (marginObject !== undefined && !marginObject.hasClass('margin-adjusted')) {
          marginObject.css('margin-bottom', nav.outerHeight()).addClass('margin-adjusted');
        }
      } else {
        nav.removeClass('fixed-top');
        if (marginObject !== undefined && marginObject.hasClass('margin-adjusted')) {
          marginObject.removeAttr('style').removeClass('margin-adjusted');
        }
      }
    } else {
      if (navObject.position === null) {
        navObject.position = nav.position().top + nav.outerHeight();
      }
      if ($(window).scrollTop() + $(window).height() >= navObject.position) {
        nav.addClass('fixed-bottom');
        if (marginObject !== undefined && !marginObject.hasClass('margin-adjusted')) {
          marginObject.css('margin-bottom', nav.outerHeight()).addClass('margin-adjusted');
        }
      } else {
        nav.removeClass('fixed-bottom');
        if (marginObject !== undefined && marginObject.hasClass('margin-adjusted')) {
          marginObject.removeAttr('style').removeClass('margin-adjusted');
        }
      }
    }
  };

  var resetFixedNav = function(nav, placement, navObject, marginObject) {
    nav.removeClass('fixed-' + placement);
    navObject.position = null;
    if (marginObject !== undefined) {
      marginObject.removeClass('margin-adjusted');
    }
    fixedNav(nav, placement, navObject, marginObject);
  };

  var setElCoords = function(el, highlighter) {

    const coords = {
      width: el.outerWidth(),
      height: el.outerHeight(),
      left: el.position().left,
      top: el.position().top
    };

    highlighter.css({
      'left': coords.left,
      'top': coords.top,
      'height': coords.height,
      'width': coords.width
    });
  };

  var menuHighlight = function() {

    const menu = $('nav.navbar');
    const elMenuHighlight = $('<div class="navbar-highlight"></div>');
    const mainItem = $('.navbar .nav > li.current_page_parent > a').length > 0 ? $('.navbar .nav > li.current_page_parent > a') : $('.navbar .nav > li.current-menu-item > a');
    const highlightedItem = mainItem.length > 0 ? mainItem : $('.navbar .nav > li:first-of-type > a');

    menu.addClass('highlight-active').append(elMenuHighlight);

    setElCoords(highlightedItem.parent(), elMenuHighlight);

    $('.navbar .nav > li > a').on('mouseenter focus', function() {
      setElCoords($(this).parent(), elMenuHighlight);
    });

    $('.navbar .nav').on('mouseleave focusout', function() {
      setElCoords(highlightedItem.parent(), elMenuHighlight);
    });

  };

  var contentLinkHighlight = function() {

    const elContentHighlight = $('<div class="content-highlight"></div>');


    $('#content').append(elContentHighlight);

    $('#content p>a:not(.btn)').on('mouseenter focus', function() {

      const $this = $(this);
      const coords = {
        width: $this.outerWidth(),
        height: $this.outerHeight(),
        left: $this.offset().left - $('#content').offset().left,
        top: $this.offset().top - $('#content').offset().top
      };

      elContentHighlight.css({
        'left': coords.left - 5,
        'top': coords.top - 2,
        'height': coords.height + 4,
        'width': coords.width + 10
      });
    });
  };

  var WCAGScripts = function(){

    // REQUIRED - script for making the bootstrap tooltip compliant with wcag 2.1 1.4.13
    wcagTooltip();

    // removes widows and orphans from the main content - I think it should be always in use
    removeSingleWords();

    // smartmenu startup
    if ($('.navbar-nav').data('smartmenus')) {
      $('.navbar-nav').data('smartmenus').opts.bootstrapHighlightClasses = '';
    }

  };


  $(document).ready(function() {
    
    

		// if you have any navs that need to have a "skip button" this is it, just put the following button above the <nav> element
		// <a href="#" class="btn-skip-nav sr-only sr-only-focusable position-absolute btn btn-primary">Skip Navigation</a>
    /*skipNav();*/

    

    // testimonials expandable script - used in the testimonials-expandable.php partial
    $(".more").shorten({
      "showChars": 240
    });

    

    // turns on BXslider - but you should optimize this function to only the carousels that you need before going live
    /*initializeBxSlider();*/

    // tunrs on the gallery script
    /*initializeColorbox();*/

    // if you use the section-opening-times.php partial this will add an 'active' class to the current day
    /*openingHours();*/

    // if you use one of the menus from the section-menu-image.php partial
    /*imageMenu();
    imageMenuCollapse();*/

    // if you have a search option in your WordPress this is a validation script
    /*searchFormValidate();*/

    // if you have a search field toggle like Kalos
    /*showSearch();*/


    // the background effects that follow hovered links
    /*menuHighlight();
    contentLinkHighlight();*/

    // if you use bootstrap collapses for faqs this will make them wcag complaint
    /*collaspeKeyboardNav();*/

    // if you use bootstrap tabs for navigations this will make them wcag complaint
    /*tabsKeyboardNav();*/

    // STICKY NAVS //

    // if you have elements that stick to the top or bottom of the website while scrolling
    /*fixedNav($('.row-cta-book'), 'bottom', navBottom, $('body'));
    fixedNav($('.header-navigation'), 'top', navTop, $('header.banner'));*/


    // this will reset the sticky navs after the page loads to avoid rendering issues
    // $(window).load(function() {
    //   resetFixedNav($('.row-cta-book'), 'bottom', navBottom, $('body'));
    //   resetFixedNav($('.header-navigation'), 'top', navTop, $('header.banner'));
    // });

    // this will make the sticky navs fixed when they come into view
    // $(window).scroll(function() {
    //   fixedNav($('.row-cta-book'), 'bottom', navBottom, $('body'));
    //   fixedNav($('.header-navigation'), 'top', navTop, $('header.banner'));
    // });


    // Enables all necessary WCAG Scripts
    WCAGScripts();

  });

  // this function resets other functions after window resizing to avoid rendering issues - only 4 functions from the template need this, delete the ones you don't use

  var resizeTimer;

  $(window).resize(function() {
    clearTimeout(resizeTimer);
    resizeTimer = setTimeout(function() {

      /*imageMenuCollapse();
      imageMenu();
      resetFixedNav($('.row-cta-book'), 'bottom', navBottom, $('body'));
      resetFixedNav($('.header-navigation'), 'top', navTop, $('header.banner'));*/

    }, 300);
  });

})(jQuery); // Fully reference jQuery after this point.

// function for controling the background video from the section-video.php partial

/*function video(e) {
  var controls = e.getAttribute("aria-controls");
  var datavideoplaying = e.getAttribute("data-video-playing");
  var label, icon;
  if (datavideoplaying === "false") {
    label = "pause video";
    icon = '<i class="fas fa-pause" aria-hidden="true"></i>';
    datavideoplaying = "true";
    document.getElementById(controls).play();
  } else {
    label = "play video";
    icon = '<i class="fas fa-play" aria-hidden="true"></i>';
    datavideoplaying = "false";
    document.getElementById(controls).pause();
  }
  e.innerHTML = icon;
  e.setAttribute("data-video-playing", datavideoplaying);
  e.setAttribute("aria-label", label);
}*/